import { Box, Typography } from '@mui/material'
import React from 'react'

export type NameTagProps = {
  name: string
  subtitle: string
  isSmall: boolean
}

const NameTag = (props: NameTagProps) => {
  const { name, subtitle, isSmall } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={isSmall ? '0px' : '8px'}
    >
      <Typography
        variant={isSmall ? 'h3m' : 'h2'}
        sx={{
          fontWeight: isSmall ? 800 : undefined,
        }}
      >
        {name}
      </Typography>
      <Typography variant="c1">{subtitle}</Typography>
    </Box>
  )
}

export default NameTag
