import React from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { navigate } from 'gatsby'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import Hero from '../components/SharedComponents/Hero'
import { CALENDLY_LANDING_URL } from '../constants'
import StoriesImage from '../images/stories.svg'
import StoriesContent from '../components/StoriesComponents/StoriesContent'
import { useRecordPageDuration, useCalendly } from '../components/utils'
import ScheduleCall from '../components/SharedComponents/ScheduleCall'
import SectionLayout from '../components/SharedComponents/SectionLayout'
import InlineMessageAddress from '../components/SharedComponents/InlineMessageAddress'
import { ONBOARDING_PATH } from '../routeConstants'
import { WebinarModal } from '../components/SharedComponents/WebinarModal'

const pageTitle = 'Flock Stories'
const pageDescription =
  'We can tell you all about our product, but nothing says more about our process than our happy Flock Clients.'

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const Stories = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'stories',
    scheduleUrl: CALENDLY_LANDING_URL as string,
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  useRecordPageDuration()

  return (
    <PageWrapper
      title="Flock Stories"
      trackingName="stories"
      headerBackground="trustBlue.main"
    >
      <ScheduleCall {...otherCalendlyProps} />
      <Hero
        title={pageTitle}
        description={pageDescription}
        backgroundColor="trustBlue.main"
        placeholder="Enter your property address"
        ctaType={isMobile ? 'button' : 'address'}
        ctaText="Get a valuation"
        leftMd={7}
        showVideo
        src={StoriesImage}
        imgAlt="Road"
        onClick={() => navigate(ONBOARDING_PATH)}
        buttonTrackingConfig={{
          properties: {
            leadConversion: 'started',
          },
        }}
      />

      <StoriesContent />
      <SectionLayout name="inline-message" backgroundColor="green1.main">
        <Grid item xs={12}>
          <InlineMessageAddress
            text="Landlords, retire and minimize taxes."
            variant={isTablet ? 'button' : 'address'}
            label="Get Started"
          />
        </Grid>
        <Grid item xs={12} textAlign="end" pt="16px">
          <WebinarModal />
        </Grid>
      </SectionLayout>
    </PageWrapper>
  )
}

export default Stories
