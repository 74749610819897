import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { ArchFrame } from '../ArchFrame'
import NameTag from '../NameTag/NameTag'

export type PersonaFrameProps = {
  src: string
  name: string
  subtitle: string
  size?: 'large' | 'medium' | 'small'
}

const PersonaFrame = (props: PersonaFrameProps) => {
  const { src, name, subtitle, size } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <ArchFrame src={src} size={size || (isMobile ? 'small' : 'large')}>
      <NameTag name={name} subtitle={subtitle} isSmall={isMobile} />
    </ArchFrame>
  )
}

export default PersonaFrame

PersonaFrame.defaultProps = {
  size: undefined,
}
