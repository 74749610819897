import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material'
import React from 'react'
import slugify from 'slugify'
import { TrackingContextProvider } from '../TrackingContextProvider'
import { TrackingConfig, useTracking } from '../useTracking'

export type SidebarItem = {
  title: string
  description: string
}

type SidebarProps = {
  items: SidebarItem[]
  selected: number
  trackingConfig?: TrackingConfig
  onChange: (selected: number) => void
}

const Sidebar = (props: SidebarProps) => {
  const { items, selected, trackingConfig, onChange } = props
  const { track } = useTracking()
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    onChange(newValue)
    track(slugify(items[newValue].title.toLowerCase()))
  }
  const theme = useTheme()

  return (
    <TrackingContextProvider name={trackingConfig?.name || 'sidebar'}>
      <Tabs orientation="vertical" value={selected} onChange={handleChange}>
        {items.map((item, idx) => {
          const { title, description } = item
          const isSelected = selected === idx
          return (
            <Tab
              key={title}
              sx={{
                padding: '24px',
                paddingLeft: '32px',
              }}
              label={
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h2"
                    textAlign="left"
                    sx={{
                      mb: '16px',
                      fontSize: isSelected
                        ? theme.typography.h2.fontSize
                        : theme.typography.h4.fontSize,
                      lineHeight: isSelected
                        ? theme.typography.h2.lineHeight
                        : theme.typography.h4.lineHeight,
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="h4"
                    textAlign="left"
                    sx={{
                      fontWeight: 400,
                      fontSize: isSelected
                        ? // @ts-ignore
                          theme.typography[
                            // @ts-ignore
                            theme?.sidebarOptions
                              ?.selectedDescriptionFontVariant || 'h3'
                          ].fontSize
                        : // @ts-ignore
                          theme.typography[
                            // @ts-ignore
                            theme?.sidebarOptions
                              ?.unselectedDescriptionFontVariant || 'p1'
                          ].fontSize,
                      lineHeight: isSelected
                        ? // @ts-ignore
                          theme.typography[
                            // @ts-ignore
                            theme?.sidebarOptions
                              ?.selectedDescriptionFontVariant || 'h3'
                          ].lineHeight
                        : // @ts-ignore
                          theme.typography[
                            // @ts-ignore
                            theme?.sidebarOptions
                              ?.unselectedDescriptionFontVariant || 'p1'
                          ].lineHeight,
                    }}
                  >
                    {description}
                  </Typography>
                </Box>
              }
            />
          )
        })}
      </Tabs>
    </TrackingContextProvider>
  )
}

export default Sidebar

Sidebar.defaultProps = {
  trackingConfig: null,
}
