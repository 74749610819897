/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  PersonaFrame,
  QuoteText,
  Sidebar,
  TrackedButton,
} from '@flock/shared-ui'
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SidebarItem } from '@flock/shared-ui/src/components/Sidebar/Sidebar'
import { scrollIntoView, useInView } from '../utils'
import Story1Img from '../../images/stories/jack-vicki.webp'
import Story2Img from '../../images/stories/katie-mark.webp'
import Story3Img from '../../images/stories/wayne-and-vickie-l.webp'
import { ONBOARDING_PATH } from '../../routeConstants'
import MobileStoriesContainer from './MobileStoriesContainer'
import SectionLayout from '../SharedComponents/SectionLayout'

const sectionIds = ['wayne-and-vickie', 'katie-and-mark', 'jack-and-vickie']

type StoryComponent = {
  type: 'text' | 'quote'
  value: 'string'
}

type StoriesContentProps = {
  sideBarItems?: {
    title: string
    description: string
  }[]
  storyItems?: {
    id: string
    name: string
    joinedYear: string
    graphic: string
    storyComponents: StoryComponent[]
  }[]
}

const StoriesContent = (props: StoriesContentProps) => {
  const { sideBarItems, storyItems } = props
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const [activeStep, setActiveStep] = useState(0)

  const currentSection = useInView(sectionIds)

  const onChange = (newTab: number) => {
    scrollIntoView(sectionIds[newTab])
  }

  useEffect(() => {
    setActiveStep(sectionIds.indexOf(currentSection))
  }, [currentSection])

  return isTablet ? (
    <SectionLayout name="stories-content" backgroundColor="green1.main">
      {storyItems?.map((storyItem) => (
        <MobileStoriesContainer
          name={storyItem.name}
          graphic={storyItem.graphic}
          storyComponents={storyItem.storyComponents}
        />
      ))}
    </SectionLayout>
  ) : (
    <SectionLayout
      name="faq"
      backgroundColor="green1.main"
      verticalPadding="32px"
    >
      {storyItems?.map((storyItem, index) => (
        <>
          {index === 0 ? (
            <Grid
              sx={{
                position: 'sticky',
                top: '3%',
                height: 'fit-content',
                zIndex: 999,
              }}
              item
              xs={3}
              display="flex"
              justifyContent="flex-end"
            >
              <Box width="100%" pt="48px" pb="80px">
                <Sidebar
                  items={sideBarItems as SidebarItem[]}
                  onChange={onChange}
                  selected={activeStep}
                />
              </Box>
            </Grid>
          ) : (
            <Grid item xs={3} />
          )}
          <Grid item xs={6} id={storyItem.id}>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gap="32px"
              pt="48px"
              maxWidth="544px"
              id={sectionIds[index]}
              alignItems="center"
            >
              <Box mt="-128px" position="absolute" />
              <PersonaFrame
                name={storyItem.name}
                subtitle={`Joined ${storyItem.joinedYear}`}
                src={storyItem?.graphic || ''}
              />
              {storyItem.storyComponents?.map(
                (storyComponent: StoryComponent) => (
                  <>
                    {storyComponent.type === 'text' ? (
                      <Typography variant="p2">
                        {storyComponent.value}
                      </Typography>
                    ) : (
                      <QuoteText
                        text={storyComponent.value}
                        variant="stories"
                        isSmall={false}
                      />
                    )}
                  </>
                )
              )}
              <Box width="303px" height="48px" display="flex">
                <TrackedButton variant="secondary" to={ONBOARDING_PATH}>
                  Get started with Flock
                </TrackedButton>
              </Box>
              <Box justifyContent="center" display="flex" pt="80px">
                <Divider sx={{ borderColor: 'gray5.main', width: '160px' }} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} />
        </>
      ))}
    </SectionLayout>
  )
}

export default StoriesContent

StoriesContent.defaultProps = {
  sideBarItems: [
    {
      title: 'Wayne & Vickie',
      description: 'Retiring landlords turned Flock Clients.',
    },
    {
      title: 'Katie & Mark',
      description: 'Accidental landlords turned proud Flock Clients.',
    },
    {
      title: 'Jack & Vicki',
      description: 'Busy landlords turned Flock Clients.',
    },
  ],

  storyItems: [
    {
      id: 'wayne-and-vickie',
      name: 'Wayne & Vickie',
      joinedYear: '2022',
      graphic: Story3Img,
      storyComponents: [
        {
          type: 'text',
          value:
            'Meet Wayne and Vickie, newly “retired” but still working day and night as landlords.',
        },
        {
          type: 'text',
          value:
            'Wayne and Vickie started purchasing investment properties in the early 2000s. Their intention was to sell the properties when their two children went to college and use the money for tuition and additional expenses. Then the unexpected happened: the Great Financial Crisis hit just as their children started college in 2007. Wayne and Vickie turned to plan B, keeping their six rental properties and maintaining this nest egg for their own retirement.',
        },
        {
          type: 'text',
          value: `While working full-time, Wayne and Vickie self-managed the rental properties. Vickie oversaw all resident obligations, and Wayne took on property maintenance. With multiple properties across two states, management was not always the most convenient. Vickie comments, `,
        },
        {
          type: 'quote',
          value: `The worst part is collecting rent when it is late or trying to fill a vacancy in a timely manner.`,
        },
        {
          type: 'text',
          value: `The burdens of ongoing maintenance took a toll on Wayne for over 20+ years. Some exit opportunities arose naturally, but as they got closer to retiring from their full-time jobs, they wanted to “be done with toilets” and find a way to successfully retire from being landlords, too.`,
        },
        {
          type: 'text',
          value:
            'After seeing the advantages of owning rentals, Wayne and Vickie wanted to maintain their exposure to real estate. They thought about investing in REITs, but they found these investments too restrictive on redemption terms. They received Flock’s mailer, and the opportunity for a tax-deferred sale caught their eye. At the time, Vickie and Wayne were both working full-time, which would have put them in the highest tax bracket in the event of a sale. An opportunity to divest while deferring taxes was a benefit they wanted to explore more.',
        },
        {
          type: 'text',
          value: `In speaking with Flock, their decision became clear. The hesitation to give complete control and trust to a relatively new company was overpowered by Flock’s dedication and sense of duty to protect the real estate value they had nurtured.`,
        },
        {
          type: 'quote',
          value:
            'We were the perfect fit for Flock. We have been landlords and businesspeople for a long time. We were tired from that and wanted to retire. We wanted to hand over our properties and still hold value.',
        },
      ],
    },
    {
      id: 'katie-and-mark',
      name: 'Katie & Mark',
      joinedYear: '2021',
      graphic: Story2Img,
      storyComponents: [
        {
          type: 'text',
          value:
            'Katie and Mark never set out to become landlords. They are grateful to have seamlessly left the burdens behind when they transacted with Flock.',
        },
        {
          type: 'text',
          value:
            'Katie and Mark purchased their Highlands Ranch home in 2013; it was close to Mark’s work, and Katie’s job allowed her to work remotely when she was not traveling. When Mark took a new job in Boulder, he found himself commuting for a minimum of two hours each day—time he wanted to spend at home with his family—so he and Katie made the decision to move to a more convenient location. They held onto their home and transitioned it to a rental, knowing it would be worthwhile to diversify their investment portfolio with real estate.',
        },
        {
          type: 'text',
          value:
            'Neither Katie nor Mark knew the first thing about being a landlord. The one thing they did know after hearing nightmarish stories, however, was that they did not want to use property managers. While they found great renters quickly, Mark and Katie underestimated the time it would take to manage the house on top of full-time jobs. The drive to their rental from their primary residence was long, especially after a full day at work. They didn’t want to sell the home and forfeit their exposure to the housing market, and having appreciated significantly in recent years, their rental now represented a large portion of their nest egg.',
        },
        {
          type: 'text',
          value: `When Katie and Mark met with Flock, they had reservations about joining an early-stage startup without significant funding, but they found immense value in Flock’s offering. They were impressed with the team and Flock’s communication. Every question was answered, and their confidence grew. They made the decision to contribute their home, retire from being landlords, and maintain their allocation to the real estate market.`,
        },
        {
          type: 'text',
          value:
            'While Mark had performed all of the heavy lifting with property maintenance, the exchange was still a relief for Katie. When things were broken, Mark would have to leave on the weekends or be busy after the workday. It was a drain on the whole family.',
        },
        {
          type: 'quote',
          value:
            'There was so much stress around the unknowns, and now we just don’t have to worry about that. We get passive income, and we get the check without having to do anything. Plus, it’s been fun to see Flock grow.',
        },
      ],
    },

    {
      name: 'Jack & Vicki',
      id: 'jack-and-vicki',
      joinedYear: '2022',
      graphic: Story1Img,
      storyComponents: [
        {
          type: 'text',
          value: `Meet Jack and Vicki. They began their journey as landlords 30 years ago: after moving their family overseas for work, they transitioned their primary residence in Arizona to a rental property. Two years later, Jack and Vicki moved back stateside and chose to relocate to Seattle. They continued to manage their Arizona home as a rental as they waited for the opportune time to sell.`,
        },
        {
          type: 'text',
          value:
            'About eight years after moving to Seattle, Jack and Vicki inherited four properties–three rentals (two duplexes and one SFR) and a vacant lot on which they built a new home. Soon thereafter, they added a multi-family property to their portfolio. They used property managers at first, since Jack’s career was tied to international travel, and Vicki worked full-time as a commercial architect. But as Jack’s career gradually required less travel, he was able to take on more responsibilities and self-manage their rentals.',
        },
        {
          type: 'quote',
          value:
            'I’m reaching an emotional end for being an active property manager.',
        },
        {
          type: 'text',
          value: `In 2018 Jack retired from his full-time job. He and Vicki then exited two properties in their portfolio through a 1031 Exchange and a traditional sale. When they discovered Flock in 2021, Jack still managed their multi-family asset, and they had their last duplex on the market. The home was generating interest, but Jack and Vicki hadn’t found the right buyers. When they received Flock’s mailer, they were intrigued by the benefits of a 721 Exchange.`,
        },
        {
          type: 'text',
          value: `Jack and Vicki felt the risk of joining a new company as they weighed their decision. Ultimately, Flock’s strong sense of leadership, talented staff, and venture capital partners led them to contribute their duplex to Flock.`,
        },
        {
          type: 'quote',
          value:
            'When Flock came up it made a lot of sense to continue with them. Getting a quarterly return is nice, and we will see where the next few years take us.',
        },
      ],
    },
  ],
}
