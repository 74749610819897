/* eslint-disable jsx-a11y/anchor-is-valid */
import { PersonaFrame, QuoteText } from '@flock/shared-ui'
import { Box, Collapse, Grid, Link, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import Shirlee2 from '../../images/shirlee2.webp'

type StoryComponent = {
  type: 'text' | 'quote'
  value: 'string'
}

type StoriesContentProps = {
  graphic?: string
  name?: string
  storyComponents?: StoryComponent[]
  id?: string
}

const MobileStoriesContainer = (props: StoriesContentProps) => {
  const { graphic, name, storyComponents, id } = props

  const [showStory, setShowStory] = useState<boolean>(false)

  const ref = useRef<null | HTMLElement>(null)

  const onClickStory = () => {
    ref?.current?.scrollIntoView({ block: 'start' })
    setShowStory(!showStory)
  }

  const toggleShowStory = () => {
    setShowStory(!showStory)
  }

  return (
    <Grid
      container
      item
      xs={12}
      gap="32px"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        aria-label="expand box"
        tabIndex={0}
        onClick={() => {
          toggleShowStory()
        }}
        id={id}
      >
        <Box mt="-128px" position="absolute" ref={ref} />
        <PersonaFrame
          name={name as string}
          subtitle="Tap to learn more"
          src={graphic || ''}
        />
      </Box>

      <Collapse in={showStory}>
        <Box display="flex" flexDirection="column" gap="32px">
          {storyComponents?.map((storyComponent: StoryComponent) => (
            <>
              {storyComponent.type === 'text' ? (
                <Typography variant="p2">{storyComponent.value}</Typography>
              ) : (
                <QuoteText
                  text={storyComponent.value}
                  variant="stories"
                  isSmall={false}
                />
              )}
            </>
          ))}
          <Box justifyContent="center" display="flex" pb="154px">
            <Typography variant="p1">
              <Link
                onClick={() => {
                  onClickStory()
                }}
              >
                Collapse &#8212;
              </Link>
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Grid>
  )
}

export default MobileStoriesContainer

MobileStoriesContainer.defaultProps = {
  graphic: Shirlee2,
  storyComponents: [
    {
      type: 'text',
      value:
        'Shirlee is a single mom, full-time physician, and investment property owner in Denver, Colorado. She moved back to Denver in 2001 and purchased her first Denver home around 2003. Shirlee and her son lived there for 5 years before moving to prioritize proximity to school. At this time, Shirlee decided to turn her primary residence into a rental in hope of her home value appreciating and ultimately keeping the home as an investment for her son’s future. It was at that moment that Shirlee’s original purchase turned her into a default landlord.',
    },
    {
      type: 'text',
      value:
        ' Like many, Shirlee experienced the highlights and lulls of being a landlord. As a single mom and full-time physician her free time was limited and she was unable to manage the rental full time and be an attentive landlord. Her neighbor offered to manage the property for her, and eventually, she found a fantastic resident who occupied her home for 7 years.',
    },
    {
      type: 'quote',
      value:
        'I had the best of both worlds - a great resident and a reliable property manager that relieved me of being a landlord, but then things changed.',
    },
    {
      type: 'text',
      value:
        'Shirlee’s trusted resident gave notice and said she wanted to purchase a home closer to where her sister lived. At the same time, her neighbor announced he wanted to retire. Shirlee continues, “Then I became part of the worst world - I had to become a landlord and find new residents.” Not to mention, due the long-term occupancy, the home needed some TLC. ',
    },
    {
      type: 'text',
      value:
        'Shirlee was contemplating her options. As she saw it, she could sell the place, put some work into fixing the home and then sell or, third, put some work into the home and get it ready to rent again. Each option came with their own drawbacks, and then Shirlee found Flock through a piece of direct mail. Admittedly unsure of what Flock was offering there were a couple keywords that piqued her curiosity enough to reach out. After spending time with Flock, Shirlee found the solution she was looking for. Through Flock, she could keep all the benefits of owning the property, while not having to be a landlord. Shirlee proceeded with the 721 Exchange program Flock was offering. Flock took ownership of her home and more importantly took over the daily operations and management of the rental property. Meanwhile, Shirlee was able to avoid renovating her home, avoid the headache of selling, and maintain the upside of ownership through her newly held equity in Flock’s professionally managed and diversified portfolio of homes. ',
    },
    {
      type: 'quote',
      value:
        'My goal was for my two properties to act as a financial base for my son.',
    },
    {
      type: 'text',
      value:
        'Shirlee inherited another rental property from her mother. Unfortunately, she also inherited the headache of deferred maintenance. After seeing the benefits of contributing her first property she transacted on a second property with Flock. With Flock, Shirlee found her solution to leave the burdens of ownership behind while still preserving and growing her son’s nest egg.',
    },
  ],
  name: '',
  id: '',
}
