import React, { useEffect } from 'react'
import { PopupModal } from 'react-calendly'
import * as Sentry from '@sentry/gatsby'
import { User } from '../types'
import { GATSBY_ENV } from '../../constants'

type ScheduleCallProps = {
  rootElem: HTMLElement | null | undefined
  isOpen: boolean
  onClose: () => void
  scheduleUrl: string
  user?: User
  utmMedium?: string
}

const ScheduleCall = (props: ScheduleCallProps) => {
  const { scheduleUrl, rootElem, utmMedium, isOpen, onClose, user } = props
  useEffect(() => {
    if (
      isOpen === true &&
      (rootElem === null || rootElem === undefined) &&
      GATSBY_ENV === 'production'
    ) {
      Sentry.captureException(
        new Error(`Root element was not loaded before opening calendly modal`)
      )
    }
  }, [rootElem, isOpen])

  return (
    <PopupModal
      url={scheduleUrl}
      rootElement={rootElem as HTMLElement}
      utm={{
        utmMedium,
      }}
      open={isOpen}
      onModalClose={onClose}
      prefill={{
        name: user?.fullName,
        email: user?.email,
        location: `+1${user?.phoneNumber}`,
      }}
    />
  )
}

ScheduleCall.defaultProps = {
  user: null,
  utmMedium: '',
}

export default ScheduleCall
